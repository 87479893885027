<template>
  <div class="row">
    <div class="col-md-12" v-if="reservation && spot">
      <div class="card">
        <div class="card-header my-0 py-0 pr-0">
          <div class="card-title my-0">
            <div class="d-flex">
              <div class="mt-2">
                {{ spot.location.longName.toUpperCase() }}
                <div>{{ spot.loop.name }}, {{ spot.name }}</div>
              </div>
              <div class="ml-auto mt-2 mr-2">
                <div class="text-right">
                  {{ totalReservationCost | currency }}
                </div>
                <div class="text-right nights mt-1" v-if="!isDayUse">
                  {{ numberOfNights }} night(s)
                </div>
              </div>

              <div class="my-0 py-0">
                <div class="border-left my-0 py-1">
                  <button class="btn text-white" @click="edit">
                    <i class="fas fa-pencil-alt pr-1"></i> Modify
                  </button>
                </div>
                <div class="border-left border-top py-1" @click="remove">
                  <button class="btn text-white">
                    <i class="fas fa-trash-alt pr-1"></i> Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex p-2">
            <div>Park Use Fee</div>
            <div class="ml-auto text-right">
              <span
                v-if="isAdmin == false"
                v-b-popover.hover.html.top="feeBreakdown"
                class="text-primary"
                >{{ parkUseFees | currency }}</span
              >
              <span v-else>{{ parkUseFees | currency }}</span>
            </div>
          </div>
          <div class="d-flex alt-row p-2">
            <div>Transaction Fee</div>
            <div class="ml-auto text-right">
              {{ transactionFees | currency }}
            </div>
          </div>
          <!-- <div
            class="row py-3"
            v-for="addon in reservation.merchandise"
            :key="addon.id"
          >
            <div class="col-10">{{ addon.name }} ({{ addon.quantity }})</div>
            <div class="col-2 text-right">{{ addon.total | currency }}</div>
          </div> -->
          <hr />
          <div class="row mx-1">
            <div class="col-md-3 col-6 my-1">
              <span class="label">Arrival Date:</span>
              <div>{{ reservation.startDate | formatDateAsUTCDate }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Departure Date:</span>
              <div>{{ reservation.endDate | formatDateAsUTCDate }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Primary Occupant:</span>
              <div>
                {{ reservation.primaryOccupant.firstName }}
                {{ reservation.primaryOccupant.lastName }}
              </div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Spot Types:</span>
              <div>{{ spotTypes }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Number of People:</span>
              <div>{{ reservation.numberOfAdults }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Number of Vehicles:</span>
              <div>{{ reservation.numberOfVehicles }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Equipment Type:</span>
              <div>{{ equipmentType }}</div>
            </div>
            <div class="col-md-3 col-6 my-1">
              <span class="label">Equipment Length (ft):</span>
              <div>{{ reservation.equipmentLength }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-auto my-3" v-else>
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import SpotReservationService from "@/services/SpotReservationService.js";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import LocationService from "@/services/LocationService.js";
import CartService from "@/services/CartService.js";
import AdminCartService from "@/services/admin/AdminCartService.js";
import moment from "moment";

export default {
  name: "CartItem",
  data() {
    return {
      reservation: null,
      spot: null
    };
  },
  props: {
    item: Object,
    tenantId: String,
    isAdmin: { type: Boolean, default: false }
  },
  computed: {
    feeBreakdown() {
      let reservation = this.reservation;
      if (reservation == null || reservation?.reservationFees == null) {
        return "";
      }
      let fees = "";
      let feeBreakdown = reservation?.reservationFees?.find(
        x => x.feeType == "ParkUse"
      )?.feeBreakdown;
      if (feeBreakdown == null) {
        return "";
      }
      feeBreakdown.forEach(fee => {
        let amount = parseFloat(fee.amount);
        let amountString = amount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        });
        fees += `${moment
          .utc(fee.date)
          .format("ddd MM/DD/YYYY")} - ${amountString}<br/>`;
      });
      return fees;
    },
    numberOfNights() {
      const startDate = new moment(this.reservation.startDate, "MM/DD/YYYY");
      const endDate = new moment(this.reservation.endDate, "MM/DD/YYYY");
      return endDate.diff(startDate, "days");
    },
    totalReservationCost() {
      let cost = this.reservation.reservationFees.reduce((sum, fee) => {
        if (fee.feeType != "Convenience") {
          sum += fee.amount * fee.quantity;
        }
        return sum;
      }, 0);
      if (this.reservation.merchandise) {
        cost += this.reservation.merchandise.reduce((a, b) => a + b.total, 0);
      }
      return cost;
    },
    equipmentType() {
      let types = "";
      if (!this.spot || !this.spot.spotEquipmentTypes || !this.reservation) {
        return types;
      }

      const type = this.spot.spotEquipmentTypes.find(
        t => t.equipmentType.id == this.reservation.equipmentTypeId
      );
      return type.equipmentType.name;
    },
    parkUseFees() {
      return this.reservation.reservationFees.reduce((sum, fee) => {
        if (fee.feeType == "ParkUse") {
          sum += fee.amount * fee.quantity;
        }
        return sum;
      }, 0);
    },
    transactionFees() {
      return this.reservation.reservationFees.reduce((sum, fee) => {
        if (
          fee.feeType == "NewTransaction" ||
          fee.feeType == "ModifyTransaction"
        ) {
          sum += fee.amount * fee.quantity;
        }
        return sum;
      }, 0);
    },
    spotTypes() {
      let spotTypes = "";
      if (!this.spot) {
        return spotTypes;
      }
      let spotTypeList = this.spot.spotSpotTypes;
      spotTypeList = spotTypeList.sort((a, b) =>
        a.spotType.name.trim() > b.spotType.name.trim()
          ? 1
          : a.spotType.name.trim() < b.spotType.name.trim()
          ? -1
          : 0
      );
      spotTypeList.forEach((x, i) => {
        if (i + 1 === this.spot.spotSpotTypes.length) {
          spotTypes += x.spotType.name;
        } else {
          spotTypes += x.spotType.name + ", ";
        }
      });

      return spotTypes;
    },
    isDayUse() {
      if (!this.spot || !this.spot.product) return null;

      return this.spot.product.productType.bookingType.id === 2; //Day Use
    }
  },
  methods: {
    getCartService() {
      if (this.isAdmin) {
        return new AdminCartService(this.tenantId);
      }
      return new CartService(this.tenantId);
    },
    getSpotReservationService() {
      if (this.isAdmin) {
        return new AdminSpotReservationService(this.tenantId);
      }
      return new SpotReservationService(this.tenantId);
    },
    redirectToEditPage() {
      if (this.isAdmin) {
        this.$router.push("/admin/reservation-add-details").catch(() => {});
      } else {
        this.$router.push("/create-reservation").catch(() => {});
      }
      window.scrollTo(0, 0);
    },
    async getItemDetails() {
      //For now, we only know how to load reservations.  When we have other item types, we'll need to pull them specifically.
      if (this.item.productItemType == "Reservation") {
        const reservationService = this.getSpotReservationService();
        this.reservation = await reservationService.getReservation(
          this.item.productId
        ); //.then(response=>this.reservation = response);
        const locationService = new LocationService(this.tenantId);
        locationService
          .getSpotDetails(this.reservation.spotId)
          .then(response => (this.spot = response.spotDetails));
      }
    },
    async remove() {
      const cartService = this.getCartService();
      await cartService.removeItemFromCart(this.item.id, true).then(() => {
        this.$store.dispatch("transaction/decrementNumberOfItemsInCart");
        this.$emit("itemRemoved");
      });
    },
    async edit() {
      //Restore vuex reservation to current reservation
      const reservation = {
        id: this.reservation.id,
        spotId: this.reservation.spotId,
        spot: this.spot,
        firstName: this.reservation.primaryOccupant.firstName,
        lastName: this.reservation.primaryOccupant.lastName,
        street1: this.reservation.primaryOccupant.street1,
        street2: this.reservation.primaryOccupant.street2,
        city: this.reservation.primaryOccupant.city,
        state: this.reservation.primaryOccupant.state,
        zipCode: this.reservation.primaryOccupant.zipcode.trim(),
        email: this.reservation.primaryOccupant.email,
        phone: this.reservation.primaryOccupant.mobilePhone.trim(),
        alternatePhone: this.reservation.primaryOccupant.homePhone?.trim(),
        numberOfPeople: this.reservation.numberOfAdults,
        numberOfAdults: this.reservation.numberOfAdults,
        numberOfVehicles: this.reservation.numberOfVehicles,
        equipmentType: this.reservation.equipmentTypeId,
        equipmentLength: this.reservation.equipmentLength,
        locationName: this.spot.location.longName,
        locationId: this.spot.locationId,
        lockCode: this.reservation.spotHold.lockCode,
        lockedUntil: this.reservation.spotHold.holdEndDate,
        walkInReservation: this.reservation.walkInReservation,
        adaRequirementAcknowledged: this.reservation.adaRequirementAcknowledged,
        startDate: moment(this.reservation.startDate, "MM/DD/YYYY")
          .utc()
          .format("MM/DD/YYYY"),
        endDate: moment(this.reservation.endDate, "MM/DD/YYYY")
          .utc()
          .format("MM/DD/YYYY"),
        customerId: this.reservation.customerId,
        discounts: this.reservation.reservationDiscountClasses.map(
          x => x.discountClassId
        )
      };

      this.$store.dispatch("transaction/setReservation", reservation);
      const cartService = this.getCartService();
      await cartService.removeItemFromCart(this.item.id, false).then(() => {
        this.$store.dispatch("transaction/decrementNumberOfItemsInCart");
        this.$emit("itemRemoved");
        this.redirectToEditPage();
      });
    }
  },
  mounted() {
    this.getItemDetails();
  }
};
</script>

<style scoped>
.nights {
  font-size: 0.8rem;
}
.card-header {
  border-bottom-width: 0px;
}
.alt-row {
  background-color: #eee;
}
.card-body .row {
  margin-right: -20px;
  margin-left: -20px;
}
.label {
  color: #666;
  font-weight: bold;
}
.btn {
  font-size: 0.8rem;
  border: none;
}
</style>
