<template>
  <div>
    <div class="row" v-if="cart">
      <div class="col-md-12" v-for="item in cart.items" :key="item.id">
        <CartItem
          :item="item"
          :tenantId="tenantId"
          @itemRemoved="itemRemoved"
          :isAdmin="isAdmin"
        />
      </div>
    </div>
    <div v-if="cart && cart.items.length == 0">
      <p class="empty-cart py-5 pl-3">Your cart is empty!</p>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/cart/CartItem.vue";

export default {
  name: "Cart",
  components: {
    CartItem
  },
  props: {
    cart: Object,
    tenantId: String,
    isAdmin: { type: Boolean, default: false }
  },
  methods: {
    itemRemoved() {
      this.$emit("itemRemoved");
    }
  },
  watch: {
    cart() {
      if (this.cart.items.length == 0) {
        this.$store.dispatch("transaction/clearTimer");
      }
    }
  }
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
}
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
.empty-cart {
  background-color: white;
  font-size: 2rem;
}
</style>
