<template>
  <div class="row" v-if="cart && cart.items.length > 0">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title text-center my-auto">Order Summary</div>
        </div>
        <div class="card-body">
          <div
            class="d-flex pb-1"
            v-for="reservation in reservations"
            :key="reservation.id"
          >
            <div>
              <div class="park-title">
                {{ reservation.spot.location.longName.toUpperCase() }}
              </div>
              <div class="nights" v-if="!isDayUse(reservation)">
                {{ numberOfNights(reservation) }} night(s)
              </div>
            </div>
            <div class="ml-auto">
              <div class="text-right">
                {{ totalReservationCost(reservation) | currency }}
              </div>
            </div>
          </div>
          <hr />
          <div
            class="d-flex"
            v-if="
              selectedPaymentTypeId &&
                selectedPaymentTypeId == cashType.paymentTypeId
            "
          >
            <div>Total</div>
            <div class="ml-auto">
              <div class="text-right">{{ subTotal | currency }}</div>
            </div>
          </div>
          <div class="form-row justify-content-center">
            <div class="col col-8 form-group text-left">
              <div class="info-label">
                <b>Credit/Debit card Convenience Fee<sup>1</sup></b>
              </div>
            </div>
            <div class="col col-4 form-group text-right">
              <div class="info-label">
                <b>{{
                  this.convenienceFee.creditCardAmount | formatCurrency
                }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!admin">
        <div class="row">
          <button
            class="btn btn-primary mx-3 pay-now"
            type="button"
            @click="payNow(creditCardType.paymentTypeId)"
            :disabled="submittingPayment"
          >
            Pay by Credit/Debit card<sup>1</sup> -
            {{ creditCardAmount | formatCurrency }}
          </button>
        </div>
        <div class="my-2">
          <span class="fine-print">
            <sup>1</sup> CC Payment option includes
            {{ convenienceFee.creditCardPercentage }}% (minimum ${{
              convenienceFee.creditCardMinAmount
            }}) payment processing fee.
          </span>
        </div>
      </div>
      <div v-else>
        <div class="row" v-if="subTotal !== 0">
          <label class="mx-3"> Payment Type </label>
          <select
            class="form-control form-control-lg mx-3"
            id="amenityType"
            v-model="selectedPaymentTypeId"
            aria-label="Select Payment Type"
          >
            <option :value="null" aria-placeholder="Select Payment Type...">
              Select Payment Type...
            </option>
            <option
              v-for="type in paymentTypes"
              :key="type.id"
              :value="type.id"
              aria-placeholder="Select Payment Type..."
            >
              {{ type.paymentType | formatEnum }}
            </option>
          </select>
        </div>
        <div
          class="row pt-2"
          v-if="cashType && selectedPaymentTypeId === cashType.paymentTypeId"
        >
          <div class="col-6">
            <MoneyInput
              v-model="cashRecieved"
              id="cashRecieved"
              name="Cash Recieved"
            />
          </div>
          <div class="col-6">
            <label for="changeDue"> Change Due</label>
            <div class="change">{{ changeDue | formatCurrency }}</div>
          </div>
        </div>
        <div class="row">
          <button
            class="btn btn-primary mx-3 pay-now my-2"
            type="button"
            @click="payNow(selectedPaymentTypeId)"
            :disabled="
              (!selectedPaymentTypeId && subTotal !== 0) ||
                submittingPayment ||
                (selectedPaymentTypeId == cashType.paymentTypeId &&
                  changeDue == null)
            "
          >
            <span v-if="!selectedPaymentTypeId && subTotal !== 0">Pay Now</span>
            <span v-if="selectedPaymentTypeId === creditCardType.paymentTypeId"
              >Pay by credit/debit card<sup>1</sup>
              {{ creditCardAmount | formatCurrency }}</span
            >
            <span v-if="selectedPaymentTypeId === cashType.paymentTypeId"
              >Confirm Cash Received</span
            >
            <span v-if="subTotal === 0">Complete Order</span>
          </button>
        </div>
        <div class="row">
          <button
            class="btn btn-primary mx-3 pay-now"
            type="button"
            @click="continueShopping"
            :disabled="submittingPayment"
          >
            CONTINUE SHOPPING
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpotReservationService from "@/services/SpotReservationService.js";
import LocationService from "@/services/LocationService.js";
import TenantService from "@/services/TenantService.js";
import AdminLookupService from "@/services/admin/AdminLookupService.js";
import PricingService from "@/services/PricingService.js";
import moment from "moment";
import MoneyInput from "@/validation/MoneyInput.vue";

export default {
  name: "CartItem",
  components: { MoneyInput },
  data() {
    return {
      reservations: [],
      paymentTypes: [],
      convenienceFee: {},
      selectedPaymentTypeId: null,
      cashRecieved: null
    };
  },
  props: {
    cart: Object,
    tenantId: String,
    admin: Boolean,
    submittingPayment: Boolean
  },
  computed: {
    subTotal() {
      if (this.reservations.length == 0) {
        return null;
      }
      let result = this.reservations.reduce((sum, res) => {
        res.reservationFees.forEach(fee => {
          if (fee.feeType != "Convenience") {
            sum += fee.amount * fee.quantity;
          }
        });
        return sum;
      }, 0);
      this.reservations.forEach(reservation => {
        if (reservation.merchandise) {
          result += reservation.merchandise.reduce((a, b) => a + b.total, 0);
        }
      });
      return result;
    },
    creditCardAmount() {
      return this.convenienceFee?.creditCardAmount + this.subTotal;
    },
    creditCardType() {
      return this.paymentTypes.find(x => x.paymentType === "CreditCard");
    },
    cashType() {
      return this.paymentTypes.find(x => x.paymentType === "Cash");
    },
    changeDue() {
      if (this.cashRecieved == null || this.cashRecieved < this.subTotal) {
        return null;
      }
      return this.cashRecieved - this.subTotal;
    }
  },
  methods: {
    payNow(paymentTypeId) {
      this.$emit("checkout", paymentTypeId);
    },
    continueShopping() {
      if (this.admin == true) {
        this.$router.push("/admin/reservation-add/").catch(() => {});
      } else {
        this.$router.push("/").catch(() => {});
      }
    },
    async getPaymentTypes() {
      const tenantService = this.admin
        ? new AdminLookupService(this.tenantId)
        : new TenantService(this.tenantId);
      const response = await tenantService.getPaymentTypes();
      if (response?.statusCode === "Success") {
        this.paymentTypes = response.data.filter(type => type.paymentType.toLowerCase() !== "echeck");
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    async loadReservations() {
      this.reservations = [];
      //For now, we only know how to load reservations.  When we have other item types, we'll need to pull them specifically.
      if (!this.cart || !this.cart.id) {
        return;
      }

      const resService = new SpotReservationService(this.tenantId);
      const locationService = new LocationService(this.tenantId);

      //Load reservation for each reservation item in cart
      for (let i = 0; i < this.cart.items.length; i++) {
        let item = this.cart.items[i];
        let response = await resService.getReservation(item.productId);
        let spotResponse = await locationService.getSpotDetails(
          response.spotId
        );
        response.spot = spotResponse.spotDetails;
        this.reservations.push(response);
      }

      await this.getConvenienceFee();
    },
    async getConvenienceFee() {
      if (this.reservations.length == 0) return;

      let fees = [];
      this.reservations.forEach(item => {
        fees = fees.concat(
          item.reservationFees.filter(x => x.feeType !== "Convenience")
        );
      });
      const service = new PricingService(this.tenantId);
      const response = await service.getConvenienceFee({
        fees
      });
      if (response?.statusCode === "Success") {
        this.convenienceFee = response.data;
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      }
    },
    numberOfNights(reservation) {
      const startDate = new moment(reservation.startDate, "MM/DD/YYYY");
      const endDate = new moment(reservation.endDate, "MM/DD/YYYY");
      return endDate.diff(startDate, "days");
    },
    isDayUse(reservation) {
      if (!reservation.spot || !reservation.spot.product) return null;

      return reservation.spot.product.productType.bookingType.id === 2; //Day Use
    },
    totalReservationCost(reservation) {
      let cost = reservation.reservationFees.reduce((sum, fee) => {
        if (fee.feeType != "Convenience") {
          sum += fee.amount * fee.quantity;
        }
        return sum;
      }, 0);
      if (reservation.merchandise) {
        cost += reservation.merchandise.reduce((a, b) => a + b.total, 0);
      }
      return cost;
    }
  },
  watch: {
    cart() {
      this.loadReservations();
    },
    convenienceFee() {
      this.$emit("getConvenienceFee", this.convenienceFee);
    }
  },
  mounted() {
    this.loadReservations();
    this.getPaymentTypes();
  }
};
</script>

<style scoped>
.nights {
  font-size: 0.8rem;
}

.alt-row {
  font-weight: bold;
}
.park-title {
  font-weight: bold;
}
.pay-now {
  display: block;
  width: 100%;
}
.change {
  font-weight: bold;
  font-size: 2em;
}
.fine-print {
  font-size: smaller;
}
</style>
